import React, { useState } from "react"
import { Map, TileLayer, Marker } from "react-leaflet"
import { Formik } from "formik"
import * as Yup from "yup"
import emailjs from "emailjs-com"

// Components
import Layout from "../components/layout"
import Clients from "../components/Clients"
import Footer from "../components/Footer"
import SEO from "./../components/Seo"

// styled components
import {
  MainContainer,
  FormContainer,
  MapContainer,
  Input,
  Message,
  ErrorMessage,
  StatusMessage,
} from "./../styles/contact"
import { Container, Button } from "../styles/GlobalStyles"

const currentPosition = [11.115046, 76.933245]

const MailSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name Required"),
  email: Yup.string().email("Invalid email").required("Email Required"),
  message: Yup.string().min(2, "Too Short!").required("Message Required"),
})

const IndexPage = props => {
  const [mailStatus, setMailStatus] = useState()
  return (
    <Layout>
      <SEO title="Contact Us" pathname={props.location.pathname} />
      <Container>
        <MainContainer>
          <FormContainer>
            <h1>Let's Talk</h1>
            <p className="p2">
              Tell us about your project, get a quick estimate and a plan for
              making it live.
            </p>
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
              validationSchema={MailSchema}
              onSubmit={values => {
                var template_params = {
                  subject: "Newsletter Request",
                  from_name: values.name,
                  mailer_id: values.email,
                  message_html: values.message,
                }

                var service_id = "gmail"
                var template_id = "template_fyOBru9Q"
                emailjs
                  .send(
                    service_id,
                    template_id,
                    template_params,
                    "user_57uUz67osBQ62JEiYCAls"
                  )
                  .then(
                    function (response) {
                      setMailStatus("Mail sent successfully!")
                      setTimeout(() => setMailStatus(""), 5000)
                      console.log("SUCCESS!", response.status, response.text)
                    },
                    function (error) {
                      setMailStatus("Mail cannot be sent.")
                      setTimeout(() => setMailStatus(""), 5000)
                      console.log("FAILED...", error)
                    }
                  )
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <>
                  <Input
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name ? (
                    <ErrorMessage>{errors.name}</ErrorMessage>
                  ) : null}
                  <Input
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  ) : null}
                  <Message
                    placeholder="Message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                  />
                  {errors.message && touched.message ? (
                    <ErrorMessage>{errors.message}</ErrorMessage>
                  ) : null}
                  <Button aria-label="Send message" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <StatusMessage>{mailStatus}</StatusMessage>
                </>
              )}
            </Formik>
          </FormContainer>
          <MapContainer>
            {typeof window !== "undefined" ? (
              <Map
                className="mapview"
                zoom={13}
                center={currentPosition}
                zoomControl={false}
              >
                <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />
                <Marker position={currentPosition} />
              </Map>
            ) : null}
          </MapContainer>
        </MainContainer>
      </Container>
      <Clients />
      <Footer />
    </Layout>
  )
}

export default IndexPage
