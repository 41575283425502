import styled from "styled-components"

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin-bottom: 100px;
  margin-top: 50px;

  @media (max-width: 599px) {
    justify-items: center;
  }

  @media (max-width: 925px) {
    grid-template-columns: 1fr;
    justify-items: start;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 80%;
  justify-self: start;

  h1 {
    color: ${({ theme: { primary } }) => primary};
    margin-bottom: 32px;
  }

  p {
    max-width: 85%;
    margin-bottom: 32px;
  }

  @media (max-width: 599px) {
    max-width: 100%;
  }
`
export const MapContainer = styled.div`
  cursor: auto;
  height: 430px;
  width: 500px;
  border-radius: 25px;
  overflow: hidden;
  align-self: center;

  .mapview {
    height: 430px;
    width: 500px;
  }

  @media (max-width: 599px) {
    height: calc(100vw - 40px);
    width: calc(100vw - 40px);
    border-radius: 10px;
    align-self: start;
    justify-self: center;
    margin-top: 75px;
  }

  @media (min-width: 600px) {
    height: 430px;
    height: 430px;
    margin-top: 100px;
    .mapview {
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 925px) {
    height: 430px;
    height: 430px;
    margin-top: 100px;
    .mapview {
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 1150px) {
    height: 430px;
    width: 100%;
    .mapview {
      height: 430px;
      width: 100%;
    }
  }
`

export const Input = styled.input`
  height: 55px;
  width: 100%;
  background: transparent;
  border: 1px solid #9fa9ae;
  border-radius: 5px;
  margin-bottom: 22px;
  padding-left: 20px;
  box-sizing: border-box;
  outline: none;

  &:focus {
    border-color: ${({ theme: { primary } }) => primary};
  }
`
export const Message = styled.textarea`
  height: 145px;
  width: 100%;
  background: transparent;
  border: 1px solid #9fa9ae;
  border-radius: 5px;
  margin-bottom: 25px;
  padding-left: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  outline: none;
  resize: none;

  &:focus {
    border-color: ${({ theme: { primary } }) => primary};
  }
`
export const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 20px;
`

export const StatusMessage = styled.div`
  color: green;
  margin-top: 10px;
  margin-bottom: 10px;
`
